import styled from "@emotion/styled"
import React from "react"
import { useIntl } from "react-intl"
import { translateMessageId } from "../utils/translations"
import Link from "./Link"
import TrainingIcon from "./TrainingIcon"

const BoxBottom = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
`

const Items = styled.ul`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
`

const NavListItem = styled.li`
  list-style-type: none;
  list-style-image: none;
  margin-bottom: 0.5rem;
`

const TrainingLink = styled(Link)`
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`
export interface IProps {
  handleClick?: () => void
}

const SideNavTrainingsBottom: React.FC<IProps> = ({ handleClick }) => {
  const intl = useIntl()
  return (
    <BoxBottom>
      <Items>
        <NavListItem onClick={handleClick}>
          <TrainingLink
            hideArrow={true}
            href="/warmup"
            aria-label={translateMessageId("warmup", intl)}
            title={translateMessageId("warmup", intl)}
          >
            <TrainingIcon name="warmup" width="4em" height="4em" />
          </TrainingLink>
        </NavListItem>
        <NavListItem onClick={handleClick}>
          <TrainingLink
            hideArrow={true}
            href="/stretching"
            aria-label={translateMessageId("stretching", intl)}
            title={translateMessageId("stretching", intl)}
          >
            <TrainingIcon name="stretching" width="4em" height="4em" />
          </TrainingLink>
        </NavListItem>
      </Items>
    </BoxBottom>
  )
}

export default SideNavTrainingsBottom
