import React from "react"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"
import { motion } from "framer-motion"

import StyledIcon from "./StyledIcon"
import Link from "./Link"
import NakedButton from "./NakedButton"
import Translation from "./Translation"
import { translateMessageId } from "../utils/translations"
import { socialMediaLinks } from "../data/socialMediaLinks"
import { Icon } from "@chakra-ui/react"

const Container = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
  }
`

const MenuIcon = styled(StyledIcon)`
  fill: ${(props) => props.theme.colors.text};
`

const MenuButton = styled(NakedButton)`
  margin-left: 1rem;
`

const MobileModal = styled(motion.div)`
  position: fixed;
  background: ${(props) => props.theme.colors.modalBackground};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`

const mobileModalVariants = {
  open: { display: "block", opacity: 1 },
  closed: { display: "none", opacity: 0 },
}

const MenuContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.background};
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  height: 100%;
`

const mobileMenuVariants = {
  closed: { x: `-100%`, transition: { duration: 0.2 } },
  open: { x: 0, transition: { duration: 0.8 } },
}

const GlyphButton = styled.svg`
  margin: 0 0.125rem;
  width: 1.5rem;
  height: 2.5rem;
  position: relative;
  stroke-width: 2px;
  z-index: 100;
  & > path {
    stroke: ${(props) => props.theme.colors.text};
    fill: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    & > path {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
`

const hamburgerSvg =
  "M 2 13 l 10 0 l 0 0 l 10 0 M 4 19 l 8 0 M 12 19 l 8 0 M 2 25 l 10 0 l 0 0 l 10 0"

const closeSvg =
  "M 2 13 l 0 -3 l 20 0 l 0 3 M 7 14 l 10 10 M 7 24 l 10 -10 M 2 25 l 0 3 l 20 0 l 0 -3"

const glyphPathVariants = {
  closed: {
    d: hamburgerSvg,
    transition: { duration: 0.4 },
  },
  open: {
    d: [hamburgerSvg, hamburgerSvg, hamburgerSvg, hamburgerSvg, closeSvg],
    transition: { duration: 1.2 },
  },
}

const BottomMenu = styled(motion.div)`
  background: ${(props) => props.theme.colors.background};
  border-top: 1px solid ${(props) => props.theme.colors.lightBorder};
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 108px;
  align-items: center;
  width: 100%;
  max-width: 450px;
  z-index: 99;
`

const BottomItem = styled.div`
  flex: 1 1 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  & > svg {
    fill: ${(props) => props.theme.colors.text};
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    & > svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`

const BottomLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
  & > svg {
    fill: ${(props) => props.theme.colors.text};
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    & > svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`

const BottomItemText = styled.div`
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.04em;
  margin-top: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`

const MenuItems = styled.ul`
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 3rem 1rem 8rem;
`

const NavListItem = styled.li`
  margin: 0;
  margin-bottom: 3rem;
  list-style-type: none;
  list-style-image: none;
`

export const MobileNavLink = styled(Link)`
  text-decoration: none;
  margin-right: 2rem;
  margin: 1rem 0;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`
const SocialLink = styled(Link)`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

export interface IProps {
  isMenuOpen: boolean
  isDarkTheme: boolean
  toggleMenu: (item?: "menu") => void
  toggleTheme: () => void
  fromPageParameter: string
}

const MainMobileNav: React.FC<IProps> = ({
  isMenuOpen,
  isDarkTheme,
  toggleMenu,
  toggleTheme,
  fromPageParameter,
}) => {
  const intl = useIntl()

  const handleClick = (): void => {
    toggleMenu()
  }

  return (
    <Container>
      <MenuButton
        onClick={() => toggleMenu("menu")}
        aria-label={translateMessageId("aria-toggle-menu-button", intl)}
      >
        <GlyphButton viewBox="0 0 24 40">
          <motion.path
            variants={glyphPathVariants}
            initial={false}
            animate={isMenuOpen ? "open" : "closed"}
          />
        </GlyphButton>
      </MenuButton>
      <MobileModal
        animate={isMenuOpen ? "open" : "closed"}
        variants={mobileModalVariants}
        initial="closed"
        onClick={handleClick}
      />
      <MenuContainer
        aria-hidden={!isMenuOpen}
        animate={isMenuOpen ? "open" : "closed"}
        variants={mobileMenuVariants}
        initial="closed"
      >
        <MenuItems>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="/what-is-classicfit">
              <Translation id="what-is-classicfit" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="/programs">
              <Translation id="programs" />
            </MobileNavLink>
          </NavListItem>
        </MenuItems>
      </MenuContainer>

      <BottomMenu
        aria-hidden={!isMenuOpen}
        animate={isMenuOpen ? "open" : "closed"}
        variants={mobileMenuVariants}
        initial="closed"
      >
        {socialMediaLinks.map((link, idk) => {
          return (
            <SocialLink
              hideArrow={true}
              key={idk}
              href={link.to}
              color="secondary"
              aria-label={link.ariaLabel}
            >
              <Icon as={link.icon} fontSize="3xl" mr={2} />
            </SocialLink>
          )
        })}
        <BottomItem onClick={toggleTheme}>
          <MenuIcon name={isDarkTheme ? "darkTheme" : "lightTheme"} />
          <BottomItemText>
            <Translation id={isDarkTheme ? "dark-mode" : "light-mode"} />
          </BottomItemText>
        </BottomItem>
        <BottomItem onClick={handleClick}>
          <BottomLink href={`/languages/${fromPageParameter}`}>
            <MenuIcon name="language" />
            <BottomItemText>
              <Translation id="languages" />
            </BottomItemText>
          </BottomLink>
        </BottomItem>
      </BottomMenu>
    </Container>
  )
}

export default MainMobileNav
