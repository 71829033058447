import React from "react"
import styled from "@emotion/styled"
import PushupsIcon from "../assets/pushups.svg"
import DipsIcon from "../assets/dips.svg"
import PullupsIcon from "../assets/pullups.svg"
import SitUpsIcon from "../assets/sit-ups.svg"
import AbsIcon from "../assets/abs.svg"
import SquatsIcon from "../assets/squats.svg"
import RunningIcon from "../assets/running.svg"
import HiitIcon from "../assets/hiit.svg"

import MilitaryPressIcon from "../assets/military-press.svg"
import BenchPressIcon from "../assets/bench-press.svg"
import DeadliftIcon from "../assets/deadlift.svg"
import BicepsCurlIcon from "../assets/biceps-curl.svg"
import BicepsReverseCurlIcon from "../assets/biceps-reverse-curl.svg"
import OverheadTricepsExtensionIcon from "../assets/overhead-triceps-extension.svg"
import UprightRowIcon from "../assets/upright-row.svg"
import RearDeltFlyIcon from "../assets/rear-delt-fly.svg"

import WarmupIcon from "../assets/warmup.svg"
import StretchingIcon from "../assets/stretching.svg"

import ForceIcon from "../assets/force.svg"
import EnduranceIcon from "../assets/endurance.svg"

export interface IProps {
  name?: string
  width?: string
  height?: string
}

const TrainingIcon: React.FC<IProps> = ({
  name = "",
  width = "3em",
  height = "3em",
}) => (
  <StyledIcon>
    {name === "pushups" && <PushupsIcon width={width} height={height} />}
    {name === "dips" && <DipsIcon width={width} height={height} />}
    {name === "pullups" && <PullupsIcon width={width} height={height} />}
    {name === "sit-ups" && <SitUpsIcon width={width} height={height} />}
    {name === "abs" && <AbsIcon width={width} height={height} />}
    {name === "squats" && <SquatsIcon width={width} height={height} />}
    {name === "running" && <RunningIcon width={width} height={height} />}
    {name === "hiit" && <HiitIcon width={width} height={height} />}

    {name === "military-press" && (
      <MilitaryPressIcon width={width} height={height} />
    )}
    {name === "bench-press" && <BenchPressIcon width={width} height={height} />}
    {name === "deadlift" && <DeadliftIcon width={width} height={height} />}
    {name === "biceps-curl" && <BicepsCurlIcon width={width} height={height} />}
    {name === "biceps-reverse-curl" && (
      <BicepsReverseCurlIcon width={width} height={height} />
    )}
    {name === "overhead-triceps-extension" && (
      <OverheadTricepsExtensionIcon width={width} height={height} />
    )}
    {name === "upright-row" && <UprightRowIcon width={width} height={height} />}
    {name === "rear-delt-fly" && (
      <RearDeltFlyIcon width={width} height={height} />
    )}

    {name === "warmup" && <WarmupIcon width={width} height={height} />}
    {name === "stretching" && <StretchingIcon width={width} height={height} />}

    {name === "force" && (
      <ForceIcon
        width={width}
        height={height}
        className="title"
        style={{ borderBottom: "1px solid grey" }}
      />
    )}
    {name === "endurance" && (
      <EnduranceIcon
        width={width}
        height={height}
        className="title"
        style={{ borderBottom: "1px solid grey" }}
      />
    )}
  </StyledIcon>
)

const StyledIcon = styled.div`
  fill: ${(props) => props.theme.colors.text};

  &:hover svg {
    fill: ${(props) => props.theme.colors.primary};
  }

  .title {
    fill: grey;
  }

  &:hover .title {
    fill: grey;
  }
`

export default TrainingIcon
