import { TrainingsLinks } from "../types"

export const enduranceLinks: TrainingsLinks = [
    {
        iconTag: "pushups",
        to: "/pushups",
        id: "pushups",
    },
    {
        iconTag: "dips",
        to: "/dips",
        id: "dips",
    },
    {
        iconTag: "pullups",
        to: "/pullups",
        id: "pullups",
    },
    {
        iconTag: "sit-ups",
        to: "/sit-ups",
        id: "sit-ups",
    },
    {
        iconTag: "abs",
        to: "/abs",
        id: "abs",
    },
    {
        iconTag: "squats",
        to: "/squats",
        id: "squats",
    },
    {
        iconTag: "running",
        to: "/running",
        id: "running",
    },
    {
        iconTag: "hiit",
        to: "/hiit",
        id: "hiit",
    },
]

export const forceLinks: TrainingsLinks = [
    {
        iconTag: "military-press",
        to: "/military-press",
        id: "military-press",
    },
    {
        iconTag: "bench-press",
        to: "/bench-press",
        id: "bench-press",
    },

    {
        iconTag: "deadlift",
        to: "/deadlift",
        id: "deadlift",
    },
    {
        iconTag: "biceps-curl",
        to: "/biceps-curl",
        id: "biceps-curl",
    },
    {
        iconTag: "biceps-reverse-curl",
        to: "/biceps-reverse-curl",
        id: "biceps-reverse-curl",
    },
    {
        iconTag: "overhead-triceps-extension",
        to: "/overhead-triceps-extension",
        id: "overhead-triceps-extension",
    },
    {
        iconTag: "upright-row",
        to: "/upright-row",
        id: "upright-row",
    },
    {
        iconTag: "rear-delt-fly",
        to: "/rear-delt-fly",
        id: "rear-delt-fly",
    },
]