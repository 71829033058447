import React from "react"
import styled from "@emotion/styled"
import { IconContext } from "react-icons"
import { MdBrightness2, MdClose, MdLanguage, MdWbSunny } from "react-icons/md"

export interface IProps {
  name?: string
  color?: string | boolean
  size?: string
  className?: string
}

const Icon: React.FC<IProps> = ({ name = "", size = "24", className }) => (
  <IconContext.Provider value={{ size: size, className }}>
    {name === "darkTheme" && <MdBrightness2 />}
    {name === "language" && <MdLanguage />}
    {name === "lightTheme" && <MdWbSunny />}
    {name === "close" && <MdClose />}
  </IconContext.Provider>
)

const StyledIcon = styled(Icon)`
  fill: ${(props) =>
    props.color ? props.color : props.theme.colors.secondary};

  &:hover svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`

export default StyledIcon
