import styled from "@emotion/styled"

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 2rem 3rem 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`

export const Content = styled.div`
  padding: 1rem 2rem;
  width: 100%;
`
export const dropdownIconContainerVariant = {
  open: {
    rotate: 0,
    y: 3,
    transition: {
      duration: 0.4,
    },
  },
  closed: { rotate: -90, y: 0 },
}

// MDX components

export const Paragraph = styled.p`
  font-size: 1rem;
  margin: 2rem 0 1rem;
  color: ${(props) => props.theme.colors.text300};
`

export const Header1 = styled.h1`
  font-weight: 700; // This overrides base h1 styling of 400

  /* Prevent nav overlap */
  &:before {
    content: "";
    display: block;
    height: 140px;
    margin-top: -140px;
    visibility: hidden;
  }

  /* Hide anchor link */
  a {
    display: none;
  }
`

export const Header2 = styled.h2`
  font-weight: 700; // This overrides base h2 styling of 600

  /* Needed to fix issues of header padding overlapping links */
  /* https://github.com/confluenza/confluenza/pull/17 */
  position: inherit !important;

  /* Prevent nav overlap */
  &:before {
    content: "";
    display: block;
    height: 120px;
    margin-top: -120px;
    visibility: hidden;
  }

  /* Hide anchor link */
  a {
    display: none;
  }
`

export const Header3 = styled.h3`
  /* Needed to fix issues of header padding overlapping links */
  /* https://github.com/confluenza/confluenza/pull/17 */
  position: inherit !important;

  /* Prevent nav overlap */
  &:before {
    content: "";
    display: block;
    height: 120px;
    margin-top: -120px;
    visibility: hidden;
  }

  /* Hide anchor link */
  a {
    display: none;
  }
`

export const Header4 = styled.h4`
  font-weight: 600; // This overrides base h2 styling of 400

  /* Needed to fix issues of header padding overlapping links */
  /* https://github.com/confluenza/confluenza/pull/17 */
  position: unset !important;

  /* Prevent nav overlap */
  &:before {
    content: "";
    display: block;
    height: 120px;
    margin-top: -120px;
    visibility: hidden;
  }

  /* Hide anchor link */
  a {
    display: none;
  }
`
