import * as React from "react"
import styled from "@emotion/styled"
import Link from "./Link"
import { Icon, IconButton, useColorMode, Text } from "@chakra-ui/react"
import { MdWbSunny, MdBrightness2, MdLanguage } from "react-icons/md"
import ButtonLink from "./ButtonLink"
import { translateMessageId } from "../utils/translations"
import { useIntl } from "react-intl"
import Translation from "./Translation"
import MainMobileNav from "./MainMobileNav"
import { socialMediaLinks } from "../data/socialMediaLinks"

const NavContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
`

const StyledNav = styled.nav`
  height: ${(props) => props.theme.variables.navHeight};
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
`
const NavContent = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.xl};
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    align-items: center;
    justify-content: space-between;
  }
`

const InnerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    display: none;
  }
`
const LeftItems = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`

const RightItems = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`

const MainNavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;

  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  padding: 0 1rem;

  color: ${(props) => props.theme.colors.text};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
  border-right: 1px solid ${(props) => props.theme.colors.text};
`

const SocialLink = styled(Link)`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const HomeLogo = styled.span`
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
`

export interface IProps {
  path: string
}

const MainNav: React.FC<IProps> = ({ path }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDarkTheme = colorMode === "dark"
  const intl = useIntl()
  const splitPath = path.split("/")
  const fromPageParameter =
    splitPath.length > 3 && splitPath[2] !== "languages"
      ? `?from=/${splitPath.slice(2).join("/")}`
      : ""

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const handleMenuToggle = (item?: "menu"): void => {
    if (item === "menu") {
      setIsMenuOpen(!isMenuOpen)
    } else {
      setIsMenuOpen(false)
    }
  }

  return (
    <NavContainer>
      <StyledNav aria-label={translateMessageId("nav-primary", intl)}>
        <NavContent>
          <MainNavLink href="/" aria-label={translateMessageId("home", intl)}>
            <HomeLogo>ClassicFit.org</HomeLogo>
            {/*TODO: replace by true logo*/}
          </MainNavLink>
          {/* Desktop */}
          <InnerContent>
            <LeftItems>
              <MainNavLink href="/what-is-classicfit">
                <Translation id="what-is-classicfit" />
              </MainNavLink>
              <MainNavLink href="/programs">
                <Translation id="programs" />
              </MainNavLink>
            </LeftItems>
            <RightItems>
              {socialMediaLinks.map((link, idk) => {
                return (
                  <SocialLink
                    hideArrow={true}
                    key={idk}
                    href={link.to}
                    color="secondary"
                    aria-label={link.ariaLabel}
                  >
                    <Icon as={link.icon} fontSize="3xl" mr={2} />
                  </SocialLink>
                )
              })}
              <IconButton
                aria-label={
                  isDarkTheme ? "Switch to Light Theme" : "Switch to Dark Theme"
                }
                icon={
                  <Icon
                    as={isDarkTheme ? MdWbSunny : MdBrightness2}
                    fontSize="2xl"
                  />
                }
                variant="icon"
                _hover={{ color: "primary" }}
                onClick={toggleColorMode}
              />
              <ButtonLink
                href={`/languages/${fromPageParameter}`}
                variant="icon"
              >
                <Icon as={MdLanguage} fontSize="2xl" ml={1} />
                <Text as="span" pl={2}>
                  <Translation id="languages" />
                </Text>
              </ButtonLink>
            </RightItems>
          </InnerContent>

          {/* Mobile */}
          <MainMobileNav
            isMenuOpen={isMenuOpen}
            isDarkTheme={isDarkTheme}
            toggleMenu={handleMenuToggle}
            toggleTheme={toggleColorMode}
            fromPageParameter={fromPageParameter}
          />
        </NavContent>
      </StyledNav>
    </NavContainer>
  )
}

export default MainNav
