import { Icon } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import { IconType } from "react-icons"
import { useIntl } from "react-intl"
import { TrainingsLinks } from "../types"
import { translateMessageId } from "../utils/translations"
import Link from "./Link"
import TrainingIcon from "./TrainingIcon"

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Items = styled.ul`
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
`

const NavListItem = styled.li`
  list-style-type: none;
  list-style-image: none;
  margin-bottom: 0.5rem;
`

const TrainingLink = styled(Link)`
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

export interface IProps {
  titleIconTag: string
  trainingsLinks: TrainingsLinks
  handleClick?: () => void
}

const SideNavTrainingsTop: React.FC<IProps> = ({
  titleIconTag,
  trainingsLinks,
  handleClick,
}) => {
  const intl = useIntl()

  return (
    <Box>
      <Items>
        <NavListItem>
          <TrainingIcon name={titleIconTag} />
        </NavListItem>
        {trainingsLinks.map((link) => {
          return (
            <NavListItem onClick={handleClick}>
              <TrainingLink
                hideArrow={true}
                key={link.id}
                href={link.to}
                aria-label={translateMessageId(link.id, intl)}
                title={translateMessageId(link.id, intl)}
              >
                <TrainingIcon name={link.iconTag} />
              </TrainingLink>
            </NavListItem>
          )
        })}
      </Items>
    </Box>
  )
}

export default SideNavTrainingsTop
