import styled from "@emotion/styled"
import React from "react"
import { enduranceLinks, forceLinks } from "../data/trainingsLinks"

import SideMobileNavTrainings from "./SideMobileNavTrainings"
import SideNavTrainingsBottom from "./SideNavTrainingsBottom"
import SideNavTrainingsTop from "./SideNavTrainingsTop"

const NavContainer = styled.div`
  position: sticky;
  z-index: 999;
  border-right: 1px solid ${(props) => props.theme.colors.text};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3fr 1fr;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    display: none;
  }
`

function SideNavTrainings() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const handleMenuToggle = (item?: "menu"): void => {
    if (item === "menu") {
      setIsMenuOpen(!isMenuOpen)
    } else {
      setIsMenuOpen(false)
    }
  }

  return (
    <NavContainer>
      {/* Desktop */}
      <Grid>
        <SideNavTrainingsTop
          trainingsLinks={enduranceLinks}
          titleIconTag="endurance"
        />
        <SideNavTrainingsTop trainingsLinks={forceLinks} titleIconTag="force" />
        <SideNavTrainingsBottom />
      </Grid>
      {/* Mobile */}
      <SideMobileNavTrainings
        isMenuOpen={isMenuOpen}
        toggleMenu={handleMenuToggle}
      />
    </NavContainer>
  )
}

export default SideNavTrainings
