import { Box, Flex, SimpleGrid, useToken } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"
import { Lang } from "../utils/languages"
import { getLocaleTimestamp } from "../utils/time"
import Link from "./Link"
import Translation from "./Translation"

const FootNavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 2rem;

  color: ${(props) => props.theme.colors.text};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`

const BoxContainer = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.text};
`

export interface IProps {}

const Footer: React.FC<IProps> = () => {
  const intl = useIntl()
  const [medBp] = useToken("breakpoints", ["md"])

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allSiteBuildMetadata {
            edges {
              node {
                buildTime
              }
            }
          }
        }
      `}
      render={(data) => (
        <BoxContainer as="footer" p="1rem 2rem">
          <Flex
            fontSize="sm"
            alignItems="center"
            flexWrap="wrap"
            color="text200"
          >
            <Translation id="website-last-updated" />:{" "}
            {getLocaleTimestamp(
              intl.locale as Lang,
              data.allSiteBuildMetadata.edges[0].node.buildTime
            )}
          </Flex>
          <SimpleGrid
            gap={4}
            justifyContent="space-between"
            gridTemplateColumns="repeat(5, auto)"
            sx={{
              "@media (max-width: 1300px)": {
                gridTemplateColumns: "repeat(3, auto)",
              },
              [`@media (max-width: ${medBp})`]: {
                gridTemplateColumns: "repeat(2, auto)",
              },
              "@media (max-width: 500px)": {
                gridTemplateColumns: "auto",
              },
            }}
          >
            <FootNavLink href="/disclaimer">
              <Translation id="disclaimer" />
            </FootNavLink>
            <FootNavLink href="/contact-us">
              <Translation id="contact-us" />
            </FootNavLink>
            <FootNavLink href="/copyright">
              <Translation id="copyright" />
            </FootNavLink>
            <FootNavLink href="/cookie-policy">
              <Translation id="cookie-policy" />
            </FootNavLink>
            <FootNavLink href="/privacy-policy">
              <Translation id="privacy-policy" />
            </FootNavLink>
          </SimpleGrid>
        </BoxContainer>
      )}
    />
  )
}

export default Footer
