import * as React from "react"
import MainNav from "./MainNav"
import { IntlProvider } from "react-intl"
import { Flex, SimpleGrid, useColorModeValue } from "@chakra-ui/react"
import { Context } from "../types"
import { LocaleProvider } from "gatsby-theme-i18n"
import { ThemeProvider } from "@emotion/react"
import { lightTheme, darkTheme } from "../theme"
import Footer from "./Footer"
import SideNavTrainings from "./SideNavTrainings"
import SideNavDetails from "./SideNavDetails"
import styled from "@emotion/styled"
import Translation from "./Translation"
import { HeadFC } from "gatsby"

export interface IProps {
  children?: React.ReactNode
  path: string
  pageContext: Context
}

const LabelAd = styled.p`
  color: grey;
  text-align: center;
  font-size: smaller;
  text-transform: uppercase;
`
const ContentAd = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.text};
`

const Layout: React.FC<IProps> = ({ pageContext, path, children }) => {
  // TODO: tmp - for backward compatibility with old theme
  const theme = useColorModeValue(lightTheme, darkTheme)
  const locale = pageContext.locale
  const messages = require(`../intl/${locale}.json`)

  return (
    <LocaleProvider pageContext={pageContext}>
      {/* our current react-intl types does not support react 18 */}
      {/* TODO: once we upgrade react-intl to v6, remove this ts-ignore */}
      {/* @ts-ignore */}
      <IntlProvider locale={locale!} key={locale} messages={messages}>
        <ThemeProvider theme={theme}>
          <Flex
            position="relative"
            margin="0px auto"
            minHeight="100vh"
            flexFlow="column"
            maxW={{
              lg: lightTheme.variables.maxPageWidth,
            }}
          >
            <MainNav path={path} />
            <Flex flexDirection="row">
              <SideNavTrainings />
              <SimpleGrid
                width="100%"
                gridTemplateColumns="6fr 1fr"
                sx={{
                  [`@media (max-width: ${theme.breakpoints.l})`]: {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <Flex
                  bgColor="ednBackground"
                  flexDirection="column"
                  alignItems="flex-start"
                  overflow="visible"
                  width="100%"
                  flexGrow="1"
                >
                  {children}
                </Flex>
                <SideNavDetails path={path} />
              </SimpleGrid>
            </Flex>

            {/* <ContentAd>
              <LabelAd>
                <Translation id="label-ad" />
              </LabelAd>

              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5672000154487377"
                crossOrigin="anonymous"
              ></script>
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-5672000154487377"
                data-ad-slot="7842462193"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            </ContentAd> */}
            <Footer />
          </Flex>
        </ThemeProvider>
      </IntlProvider>
    </LocaleProvider>
  )
}

export default Layout
