import React, { useState, ReactNode, useEffect } from "react"
import { Box, HStack, Icon } from "@chakra-ui/react"

import { motion } from "framer-motion"
import { MdExpandMore } from "react-icons/md"
import { useIntl } from "react-intl"

import Link, { IProps as ILinkProps } from "./Link"
import Translation from "./Translation"
import { dropdownIconContainerVariant } from "./SharedStyledComponents"

import mainDetailsLinks from "../data/main-details-links.yaml"
import pushupsDetailsLinks from "../data/pushups-details-links.yaml"
import pullupsDetailsLinks from "../data/pullups-details-links.yaml"
import dipsDetailsLinks from "../data/dips-details-links.yaml"
import absDetailsLinks from "../data/abs-details-links.yaml"
import situpsDetailsLinks from "../data/sit-ups-details-links.yaml"
import squatsDetailsLinks from "../data/squats-details-links.yaml"
import runningDetailsLinks from "../data/running-details-links.yaml"
import hiitDetailsLinks from "../data/hiit-details-links.yaml"
import benchPressDetailsLinks from "../data/bench-press-details-links.yaml"
import militaryPressDetailsLinks from "../data/military-press-details-links.yaml"
import deadliftDetailsLinks from "../data/deadlift-details-links.yaml"
import bicepsCurlDetailsLinks from "../data/biceps-curl-details-links.yaml"
import bicepsReverseCurlDetailsLinks from "../data/biceps-reverse-curl-details-links.yaml"
import overheadTricepsExtensionDetailsLinks from "../data/overhead-triceps-extension-details-links.yaml"
import uprightRowDetailsLinks from "../data/upright-row-details-links.yaml"
import rearDeltFlyDetailsLinks from "../data/rear-delt-fly-details-links.yaml"
import warmupDetailsLinks from "../data/warmup-details-links.yaml"
import stretchingDetailsLinks from "../data/stretching-details-links.yaml"
import { translateMessageId } from "../utils/translations"
import { DetailsLink } from "../types"

const innerLinksVariants = {
  open: {
    opacity: 1,
    display: "block",
  },
  closed: {
    opacity: 0,
    display: "none",
  },
}

const LinkContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <HStack
      w="full"
      justify="space-between"
      py={2}
      pr={4}
      pl={8}
      _hover={{ bgColor: "ednBackground" }}
    >
      {children}
    </HStack>
  )
}

const SideNavLink: React.FC<ILinkProps> = ({ children, ...props }) => {
  return (
    <Link
      w="full"
      textDecoration="none"
      color="text"
      _hover={{ textDecoration: "none", color: "primary" }}
      _active={{ color: "primary" }}
      {...props}
    >
      {children}
    </Link>
  )
}

export interface IPropsNavLink {
  item: DetailsLink
  path: string
}

const NavLink: React.FC<IPropsNavLink> = ({ item, path }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  if (item.items) {
    return (
      <Box>
        <LinkContainer>
          {item.to && (
            <SideNavLink href={item.to} isPartiallyActive={false}>
              <Translation id={item.id} />
            </SideNavLink>
          )}
          {!item.to && (
            <Box w="full" cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
              <Translation id={item.id} />
            </Box>
          )}
          <Box
            as={motion.div}
            onClick={() => setIsOpen(!isOpen)}
            variants={dropdownIconContainerVariant}
            animate={isOpen ? "open" : "closed"}
            cursor="pointer"
          >
            <Icon as={MdExpandMore} boxSize={6} color="secondary" />
          </Box>
        </LinkContainer>
        <Box
          as={motion.div}
          fontSize="sm"
          lineHeight="tall"
          fontWeight="normal"
          ml={4}
          key={item.id}
          animate={isOpen ? "open" : "closed"}
          variants={innerLinksVariants}
          initial="open"
        >
          {item.items.map((childItem, idx) => (
            <NavLink item={childItem} path={path} key={idx} />
          ))}
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <LinkContainer>
        <SideNavLink href={item.to} isPartiallyActive={false}>
          <Translation id={item.id} />
        </SideNavLink>
      </LinkContainer>
    </Box>
  )
}

export interface IProps {
  path: string
}

const SideNavDetails: React.FC<IProps> = ({ path }) => {
  const intl = useIntl()
  const [detailsLinks, setDetailsLinks] =
    useState<DetailsLink[]>(mainDetailsLinks)

  // TODO: make this code more robust by spliting the link and doing the comparison only on the training path and use "startsWith" in order to avoid incorrect load of sideNavDetails for other links containing these words in the path
  useEffect(() => {
    if (path.includes("pushups")) {
      setDetailsLinks(pushupsDetailsLinks)
    } else if (path.includes("pullups")) {
      setDetailsLinks(pullupsDetailsLinks)
    } else if (path.includes("dips")) {
      setDetailsLinks(dipsDetailsLinks)
    } else if (path.includes("abs")) {
      setDetailsLinks(absDetailsLinks)
    } else if (path.includes("sit-ups")) {
      setDetailsLinks(situpsDetailsLinks)
    } else if (path.includes("squats")) {
      setDetailsLinks(squatsDetailsLinks)
    } else if (path.includes("running")) {
      setDetailsLinks(runningDetailsLinks)
    } else if (path.includes("hiit")) {
      setDetailsLinks(hiitDetailsLinks)
    } else if (path.includes("military-press")) {
      setDetailsLinks(militaryPressDetailsLinks)
    } else if (path.includes("bench-press")) {
      setDetailsLinks(benchPressDetailsLinks)
    } else if (path.includes("deadlift")) {
      setDetailsLinks(deadliftDetailsLinks)
    } else if (path.includes("biceps-curl")) {
      setDetailsLinks(bicepsCurlDetailsLinks)
    } else if (path.includes("biceps-reverse-curl")) {
      setDetailsLinks(bicepsReverseCurlDetailsLinks)
    } else if (path.includes("overhead-triceps-extension")) {
      setDetailsLinks(overheadTricepsExtensionDetailsLinks)
    } else if (path.includes("upright-row")) {
      setDetailsLinks(uprightRowDetailsLinks)
    } else if (path.includes("rear-delt-fly")) {
      setDetailsLinks(rearDeltFlyDetailsLinks)
    } else if (path.includes("warmup")) {
      setDetailsLinks(warmupDetailsLinks)
    } else if (path.includes("stretching")) {
      setDetailsLinks(stretchingDetailsLinks)
    } else {
      setDetailsLinks(mainDetailsLinks)
    }
  }, [path])

  return (
    <Box
      as="nav"
      position="sticky"
      pt={8}
      pb={16}
      minW="250"
      overflowY="auto"
      transition="transform 0.2s ease"
      borderLeft="1px solid grey"
      aria-label={translateMessageId("nav-main-details-links", intl)}
    >
      {detailsLinks.map((item, idx) => (
        <NavLink item={item} path={path} key={idx} />
      ))}
    </Box>
  )
}

export default SideNavDetails
